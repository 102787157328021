body {
  font-family: "Pretendard Variable", Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #e5f6f5;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: inherit;
}
